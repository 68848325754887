onComplete(function () {
  var process = function (liquidContent) {
    var processElement = function (wrapperElement) {
      if (!liquidContent) {
        // If no data is available the only show the fallbacks.
        for (var childIndex = 0; childIndex < wrapperElement.childElementCount; childIndex++) {
          var childElement = wrapperElement.children[childIndex];
          if (childElement.hasAttribute('data-leadinfo-fallback')) {
            childElement.removeAttribute('hidden');
          } else {
            childElement.setAttribute('hidden', true);
          }
        }
        return;
      }

      // Loop through elements looking for a match.
      var matchFound = false;
      for (var childIndex = 0; childIndex < wrapperElement.childElementCount; childIndex++) {
        var childElement = wrapperElement.children[childIndex];

        // Match has already been found so hide subsequent elements.
        if (matchFound) {
          childElement.setAttribute('hidden', true);
          continue;
        }

        // Hide the preload element.
        if (childElement.hasAttribute('data-leadinfo-preload')) {
          childElement.setAttribute('hidden', true);
        }
        // Always show fallback when encountered.
        if (childElement.hasAttribute('data-leadinfo-fallback')) {
          childElement.removeAttribute('hidden');
          matchFound = true;
          continue;
        }
        // Check if the attribute matches with the data.
        if (childElement.hasAttribute('data-leadinfo-match')) {
          var matchQuery = childElement.getAttribute('data-leadinfo-match');
          if (window.NCLeadinfo.matchByLiquidContent(liquidContent, matchQuery)) {
            matchFound = true;
            childElement.removeAttribute('hidden');
            continue;
          }
        }
      }
    };
    var wrapperElements = document.body.querySelectorAll('[data-leadinfo-match-container]');
    for (var wrapperIndex = 0; wrapperIndex < wrapperElements.length; wrapperIndex++) {
      processElement(wrapperElements[wrapperIndex]);
    }
  };

  // Invoke when available.
  window.NCLeadinfo.onLiquidContent(process);

  // Re-invoke when switching modes.
  if (window.editor) {
    window.editor.addEventListener('reloaded', function () {
      if (window.editor.getMode() === window.Editor.MODES.idle) {
        window.NCLeadinfo.onLiquidContent(process);
      } else {
        // Show all options.
        var wrapperElements = document.body.querySelectorAll('[data-leadinfo-match-container]');
        for (var wrapperIndex = 0; wrapperIndex < wrapperElements.length; wrapperIndex++) {
          var wrapperElement = wrapperElements[wrapperIndex];
          for (var childIndex = 0; childIndex < wrapperElement.childElementCount; childIndex++) {
            var childElement = wrapperElement.children[childIndex];
            childElement.removeAttribute('hidden');
          }
        }
      }
    });
  }
});